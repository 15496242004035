<template>
	<div>
		<div class="box">
			<div class="img_box">
				<img src="@/assets/image/liability/success.png" />
			</div>
			<div class="title">支付成功</div>
			<div class="btn" @click="download">{{ isLink ? '保单下载' : '正在生成保单···' }}</div>
			<div class="btn btn2" @click="handleHome">返回首页</div>
		</div>
		<fileDownload :show.sync="show" :url="isLink" @update:show="val => (show = val)" name="保单" />
	</div>
</template>

<script>
import { getBillInfo } from '@/request/api';
import fileDownload from '@/components/fileDownload.vue';
export default {
	data() {
		return {
			isLink: '',
			timer: '',
			show: false,
		};
	},
	components: {
		fileDownload,
	},
	methods: {
		handleHome() {
			window.location.replace(localStorage.getItem('indexUrl'));
		},
		download() {
			if (this.isLink) {
				// window.open(this.isLink);
				this.show = true;
			}
		},
	},
	async mounted() {
		const billId = localStorage.getItem('billId');
		if (!billId) return;
		this.timer = setInterval(async () => {
			const res = await getBillInfo(billId);
			if (res?.insBillPFile && res?.insBillPFile.urlPath) {
				this.isLink = res.insBillPFile.urlPath;
				clearInterval(this.timer);
			}
		}, 5000);
	},
	beforeDestroy() {
		clearInterval(this.timer);
	},
};
</script>

<style lang="scss" scoped>
.box {
	width: 4.8rem;
	font-size: 0.24rem;
	margin: 2.88rem auto 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 0.24rem;
	.img_box {
		width: 1.4rem;
		height: 1.4rem;
		img {
			width: 100%;
			height: 100%;
		}
	}
	.title {
		font-size: 0.36rem;
		color: rgba(0, 0, 0, 0.8);
		margin: 0.4rem 0;
	}
	.btn {
		width: 6.6rem;
		height: 0.72rem;
		background: rgba(247, 104, 62, 0.8);
		border-radius: 0.08rem 0.08rem 0.08rem 0.08rem;
		font-weight: 700;
		color: #ffffff;
		font-size: 0.32rem;
		text-align: center;
		line-height: 0.72rem;
	}
	.btn2 {
		background: $color_main;
		margin-top: 0.4rem;
	}
}
</style>
